import React from 'react';
import MyRoutes from './routes/Routes';
import './css/App.css';

const App = () => {
  return (
    <MyRoutes />
  );
}

export default App;
