import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Button, Card, CardBody, CardHeader, CardFooter } from '@nextui-org/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';

const UpdateCustomerForm = () => {

  const { customerId } = useParams();
  const [responsibleName, setResponsibleName] = useState('');
  const [contact, setContact] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [solutionDescription, setSolutionDescription] = useState('');
  const [bots, setBots] = useState([{ apiToken: '', agentId: '', aiProvider: '', totalMinsIA: 0, totalMinsTransfer: 0 }]);
  const [costPerMinIA, setCostPerMinIA] = useState('');
  const [costPerMinTransfer, setCostPerMinTransfer] = useState('');
  const [consumptionHistory, setConsumptionHistory] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerData = async () => {
      const token = Cookies.get('token');
      if (!token) {
        toast.error('No estás autenticado. Por favor, inicia sesión.');
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/customers/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const customer = response.data;
        setResponsibleName(customer.responsibleName);
        setContact(customer.contact);
        setCompanyName(customer.companyName);
        setSolutionDescription(customer.solutionDescription);
        setBots(customer.bots.map(bot => ({
          apiToken: bot.apiToken || '',
          agentId: bot.agentId || '',
          aiProvider: bot.aiProvider || '',
          totalMinsIA: bot.totalMinsIA || 0,
          totalMinsTransfer: bot.totalMinsTransfer || 0,
        })));
        setCostPerMinIA(customer.totalCostIA);
        setCostPerMinTransfer(customer.totalCostTransfer);
        setConsumptionHistory(customer.consumptionHistory);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        toast.error('Error al obtener datos del cliente.');
      }
    };

    fetchCustomerData();
  }, [customerId, navigate]);

  const handleAddBot = () => {
    setBots([...bots, { apiToken: '', agentId: '', aiProvider: '', totalMinsIA: 0, totalMinsTransfer: 0 }]);
  };

  const handleRemoveBot = (index) => {
    setBots(bots.filter((_, i) => i !== index));
  };

  const handleBotChange = (index, field, value) => {
    const newBots = [...bots];
    newBots[index][field] = field === 'totalMinsIA' || field === 'totalMinsTransfer' ? parseFloat(value) || 0 : value;
    setBots(newBots);
  };

  // Manejador de cambios
  const handleHistoryChange = (index, field, value) => {
    const newHistory = [...consumptionHistory];
    newHistory[index][field] = field === 'total' || field === 'totalMinsIA' || field === 'totalMinsTransfer'
      ? parseFloat(value) || 0
      : value;
    setConsumptionHistory(newHistory);
  };

  const handleAddHistory = () => {
    setConsumptionHistory([...consumptionHistory, { status: 'unpaid', totalMinsIA: 0, totalMinsTransfer: 0, total: 0, date_of_payment: null }]);
  };

  const handleRemoveHistory = (index) => {
    setConsumptionHistory(consumptionHistory.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedCustomer = {
      responsibleName,
      contact,
      companyName,
      solutionDescription,
      bots: bots.map(bot => ({
        apiToken: bot.apiToken,
        agentId: bot.agentId,
        aiProvider: bot.aiProvider,
        totalMinsIA: parseFloat(bot.totalMinsIA) || 0,
        totalMinsTransfer: parseFloat(bot.totalMinsTransfer) || 0
      })),
      costPerMinIA: parseFloat(costPerMinIA) || 0,
      costPerMinTransfer: parseFloat(costPerMinTransfer) || 0,
      consumptionHistory: consumptionHistory.map(history => ({
        _id: history._id,
        status: history.status,
        totalMinsIA: parseFloat(history.totalMinsIA) || 0,
        totalMinsTransfer: parseFloat(history.totalMinsTransfer) || 0,
        total: parseFloat(history.total) || 0,
        date_of_payment: history.date_of_payment,
      })),
    };

    try {
      const token = Cookies.get('token');
      if (!token) {
        toast.error('No estás autenticado. Por favor, inicia sesión.');
        navigate('/login');
        return;
      }

      await axios.put(`${process.env.REACT_APP_API}/customers/${customerId}`, updatedCustomer, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Cliente actualizado exitosamente');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error(`Error al actualizar cliente: ${error.response?.data?.message || 'Desconocido'}`);
    }
  };

  return (
    <div className="flex bg-[#0A0A0B] min-h-screen">
      <div className="flex flex-col items-center justify-center w-full p-4">
        <Card className="bg-gray-800 text-white p-4 w-full max-w-2xl">
          <CardHeader className="text-lg font-bold">Actualizar Cliente</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="responsibleName" className="block text-sm font-medium">
                  Nombre Responsable
                </label>
                <Input
                  id="responsibleName"
                  fullWidth
                  value={responsibleName}
                  onChange={(e) => setResponsibleName(e.target.value)}
                  placeholder="Nombre Responsable"
                />
              </div>
              <div>
                <label htmlFor="contact" className="block text-sm font-medium">
                  Contacto
                </label>
                <Input
                  id="contact"
                  fullWidth
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  placeholder="Correo Electrónico"
                />
              </div>
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium">
                  Nombre de la Empresa
                </label>
                <Input
                  id="companyName"
                  fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Nombre de la Empresa"
                />
              </div>
              <div>
                <label htmlFor="solutionDescription" className="block text-sm font-medium">
                  Descripción de la Solución
                </label>
                <Input
                  id="solutionDescription"
                  fullWidth
                  value={solutionDescription}
                  onChange={(e) => setSolutionDescription(e.target.value)}
                  placeholder="Descripción de la Solución"
                />
              </div>
              {bots.map((bot, index) => (
                <div key={index} className="space-y-2">
                  <h4 className="text-sm font-bold">Bot {index + 1}</h4>
                  <div>
                    <label htmlFor={`apiToken-${index}`} className="block text-sm font-medium">
                      API Token
                    </label>
                    <Input
                      id={`apiToken-${index}`}
                      fullWidth
                      value={bot.apiToken}
                      onChange={(e) => handleBotChange(index, 'apiToken', e.target.value)}
                      placeholder="API Token"
                    />
                  </div>
                  <div>
                    <label htmlFor={`agentId-${index}`} className="block text-sm font-medium">
                      Agent ID
                    </label>
                    <Input
                      id={`agentId-${index}`}
                      fullWidth
                      value={bot.agentId}
                      onChange={(e) => handleBotChange(index, 'agentId', e.target.value)}
                      placeholder="Agent ID"
                    />
                  </div>
                  <div>
                    <label htmlFor={`aiProvider-${index}`} className="block text-sm font-medium">
                      AI Provider
                    </label>
                    <select
                      id={`aiProvider-${index}`}
                      value={bot.aiProvider}
                      onChange={(e) => handleBotChange(index, 'aiProvider', e.target.value)}
                      className="w-full p-2 rounded border border-gray-400 bg-gray-700 text-white"
                    >
                      <option value="" disabled>
                        Seleccionar Proveedor de AI
                      </option>
                      <option value="VAPI">VAPI</option>
                      <option value="RETELL">RETELL</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor={`totalMinsIA-${index}`} className="block text-sm font-medium">
                      Minutos de IA
                    </label>
                    <Input
                      id={`totalMinsIA-${index}`}
                      fullWidth
                      type="number"
                      value={bot.totalMinsIA}
                      onChange={(e) => handleBotChange(index, 'totalMinsIA', e.target.value)}
                      placeholder="Total Minutos IA"
                    />
                  </div>
                  <div>
                    <label htmlFor={`totalMinsTransfer-${index}`} className="block text-sm font-medium">
                      Minutos de Transferencia
                    </label>
                    <Input
                      id={`totalMinsTransfer-${index}`}
                      fullWidth
                      type="number"
                      value={bot.totalMinsTransfer}
                      onChange={(e) => handleBotChange(index, 'totalMinsTransfer', e.target.value)}
                      placeholder="Total Minutos Transferencia"
                    />
                  </div>
                  <Button
                    type="button"
                    onClick={() => handleRemoveBot(index)}
                    className="mt-2"
                    color="error"
                  >
                    Eliminar Bot
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={handleAddBot} className="mt-4">
                Agregar otro Bot
              </Button>
              <div>
                <label htmlFor="costPerMinIA" className="block text-sm font-medium">
                  Costo por Minuto de IA
                </label>
                <Input
                  id="costPerMinIA"
                  fullWidth
                  value={costPerMinIA}
                  onChange={(e) => setCostPerMinIA(e.target.value)}
                  placeholder="Costo por Minuto de IA"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="costPerMinTransfer" className="block text-sm font-medium">
                  Costo por Minuto de Transferencia
                </label>
                <Input
                  id="costPerMinTransfer"
                  fullWidth
                  value={costPerMinTransfer}
                  onChange={(e) => setCostPerMinTransfer(e.target.value)}
                  placeholder="Costo por Minuto de Transferencia"
                  type="number"
                />
              </div>
              {/* Historial de Consumo */}
              <h3 className="text-lg font-bold">Historial de Consumo</h3>
              {consumptionHistory.map((history, index) => (
                <div key={index} className="space-y-2">
                  <h4 className="text-sm font-bold">Historial {index + 1}</h4>
                  <div>
                    <label htmlFor={`status-${index}`} className="block text-sm font-medium">
                      Estado de Pago
                    </label>
                    <select
                      id={`status-${index}`}
                      value={history.status}
                      onChange={(e) => handleHistoryChange(index, 'status', e.target.value)}
                      className="w-full p-2 rounded border border-gray-400 bg-gray-700 text-white"
                    >
                      <option value="unpaid">Unpaid</option>
                      <option value="paid">Paid</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor={`date_of_payment-${index}`} className="block text-sm font-medium">
                      Fecha de Pago
                    </label>
                    <Input
                      id={`date_of_payment-${index}`}
                      type="date"
                      value={history.date_of_payment ? new Date(history.date_of_payment).toISOString().split('T')[0] : ''}
                      onChange={(e) => handleHistoryChange(index, 'date_of_payment', e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div>
                    <label htmlFor={`totalMinsIA-${index}`} className="block text-sm font-medium">
                      Minutos de IA
                    </label>
                    <Input
                      id={`totalMinsIA-${index}`}
                      type="number"
                      value={history.totalMinsIA}
                      onChange={(e) => handleHistoryChange(index, 'totalMinsIA', e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div>
                    <label htmlFor={`totalMinsTransfer-${index}`} className="block text-sm font-medium">
                      Minutos de Transferencia
                    </label>
                    <Input
                      id={`totalMinsTransfer-${index}`}
                      type="number"
                      value={history.totalMinsTransfer}
                      onChange={(e) => handleHistoryChange(index, 'totalMinsTransfer', e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div>
                    <label htmlFor={`total-${index}`} className="block text-sm font-medium">
                      Total
                    </label>
                    <Input
                      id={`total-${index}`}
                      type="number"
                      value={history.total}
                      onChange={(e) => handleHistoryChange(index, 'total', e.target.value)}
                      fullWidth
                    />
                  </div>
                  <Button
                    type="button"
                    onClick={() => handleRemoveHistory(index)}
                    className="mt-2"
                    color="error"
                  >
                    Eliminar Historial
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={handleAddHistory} className="mt-4">
                Agregar otro Registro de Consumo
              </Button>
              <Button type="submit" fullWidth>
                Actualizar Cliente
              </Button>
            </form>
          </CardBody>
          <CardFooter className="text-xs text-gray-400">Todos los campos son obligatorios</CardFooter>
        </Card>
      </div>
    </div>
  );
}
  export default UpdateCustomerForm;