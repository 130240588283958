import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IconHome2, IconLogout, IconUserPlus } from '@tabler/icons-react';

const Sidebar = ({ setActiveView, userData, handleLogout }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div className="flex h-screen">
      <motion.div
        className={`bg-slate-800 shadow-2xl text-white ${isOpen ? 'w-64' : 'w-16'} transition-width duration-300 ease-in-out`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        animate={{ width: isOpen ? '16rem' : '4rem' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {isOpen && (
          <div className='p-4 text-xs font-bold'>
            <h5 className="text-sm font-semibold text-gray-400">Bienvenido:</h5>
            {userData?.email ? (
              <p className="text-xs font-medium text-white">{userData.email}</p>
            ) : (
              <p className="text-xs font-medium text-white">Usuario</p>
            )}
          </div>
        )}
        <ul className="space-y-4">
          <li
            className="relative"
            onClick={() => {
              setActiveIndex(0);
              if (setActiveView) setActiveView('home');
            }}
          >
            <Link
              to="/dashboard"
              className={`flex items-center p-2 rounded ${activeIndex === 0 ? 'bg-yellow-500' : 'hover:bg-gray-700'} ${isOpen ? 'justify-start' : 'justify-center'}`}
            >
              <span className="text-xl mr-2"><IconHome2 size={24} /></span>
              {isOpen && <span className='text-xs font-bold'>Home</span>}
            </Link>
            {activeIndex === 0 && (
              <motion.div
                className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"
                layoutId="underline"
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              />
            )}
          </li>

          <li
            className="relative"
            onClick={() => {
              setActiveIndex(1);
              if (setActiveView) setActiveView('addCustomer');
            }}
          >
            <Link
              className={`flex items-center p-2 rounded ${activeIndex === 1 ? 'bg-yellow-500' : 'hover:bg-gray-700'} ${isOpen ? 'justify-start' : 'justify-center'}`}
            >
              <span className="text-xl mr-2"><IconUserPlus size={24} /></span>
              {isOpen && <span className='text-xs font-bold'>Add Customer</span>}
            </Link>
            {activeIndex === 1 && (
              <motion.div
                className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"
                layoutId="underline"
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              />
            )}
          </li>

          <li
            className="relative"
            onClick={() => {
              setActiveIndex(2);
              if (handleLogout) handleLogout();
            }}
          >
            <Link
              
              className={`flex items-center p-2 rounded ${activeIndex === 2 ? 'bg-yellow-500' : 'hover:bg-gray-700'} ${isOpen ? 'justify-start' : 'justify-center'}`}
            >
              <span className="text-xl mr-2"><IconLogout size={24} /></span>
              {isOpen && <span className='text-xs font-bold'>Logout</span>}
            </Link>
            {activeIndex === 2 && (
              <motion.div
                className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"
                layoutId="underline"
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              />
            )}
          </li>
        </ul>
      </motion.div>
    </div>
  );
};

export default Sidebar;