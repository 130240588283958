import React, { useState } from 'react';
import { Input, Button, Card, CardBody, CardHeader, CardFooter } from '@nextui-org/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddCustomerForm = ({ userData }) => {
  
  const [responsibleName, setResponsibleName] = useState('');
  const [contact, setContact] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [solutionDescription, setSolutionDescription] = useState('');
  const [bots, setBots] = useState([{ apiToken: '', agentId: '', aiProvider: '' }]);
  const [costPerMinIA, setCostPerMinIA] = useState('');
  const [costPerMinTransfer, setCostPerMinTransfer] = useState('');
  const navigate = useNavigate();

  const handleAddBot = () => {
    setBots([...bots, { apiToken: '', agentId: '', aiProvider: '' }]);
  };

  const handleRemoveBot = (index) => {
    setBots(bots.filter((_, i) => i !== index));
  };

  const handleBotChange = (index, field, value) => {
    const newBots = [...bots];
    newBots[index][field] = value;
    setBots(newBots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCustomer = {
      userId: userData._id,
      responsibleName,
      contact,
      companyName,
      solutionDescription,
      bots,
      costPerMinIA: parseFloat(costPerMinIA),
      costPerMinTransfer: parseFloat(costPerMinTransfer),
    };

    try {
      const token = Cookies.get('token');
      if (!token) {
        toast.error('No estás autenticado. Por favor, inicia sesión.');
        navigate('/login');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API}/customers`, newCustomer, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Cliente agregado exitosamente');
    } catch (error) {
      console.error('Error adding customer:', error);
      toast.error(`Error al agregar cliente: ${error.response?.data?.message || 'Desconocido'}`);
    }
  };

  return (
    <div className="flex bg-[#0A0A0B] min-h-screen">
      <div className="flex flex-col items-center justify-center w-full p-4">
        <Card className="bg-gray-800 text-white p-4 w-full max-w-2xl">
          <CardHeader className="text-lg font-bold">Agregar Cliente</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="responsibleName" className="block text-sm font-medium">
                  Nombre Responsable
                </label>
                <Input
                  id="responsibleName"
                  fullWidth
                  value={responsibleName}
                  onChange={(e) => setResponsibleName(e.target.value)}
                  placeholder="Nombre Responsable"
                />
              </div>
              <div>
                <label htmlFor="contact" className="block text-sm font-medium">
                  Contacto
                </label>
                <Input
                  id="contact"
                  fullWidth
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  placeholder="Correo Electrónico"
                />
              </div>
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium">
                  Nombre de la Empresa
                </label>
                <Input
                  id="companyName"
                  fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Nombre de la Empresa"
                />
              </div>
              <div>
                <label htmlFor="solutionDescription" className="block text-sm font-medium">
                  Descripción de la Solución
                </label>
                <Input
                  id="solutionDescription"
                  fullWidth
                  value={solutionDescription}
                  onChange={(e) => setSolutionDescription(e.target.value)}
                  placeholder="Descripción de la Solución"
                />
              </div>
              {bots.map((bot, index) => (
                <div key={index} className="space-y-2">
                  <h4 className="text-sm font-bold">Bot {index + 1}</h4>
                  <div>
                    <label htmlFor={`apiToken-${index}`} className="block text-sm font-medium">
                      API Token
                    </label>
                    <Input
                      id={`apiToken-${index}`}
                      fullWidth
                      value={bot.apiToken}
                      onChange={(e) => handleBotChange(index, 'apiToken', e.target.value)}
                      placeholder="API Token"
                    />
                  </div>
                  <div>
                    <label htmlFor={`agentId-${index}`} className="block text-sm font-medium">
                      Agent ID
                    </label>
                    <Input
                      id={`agentId-${index}`}
                      fullWidth
                      value={bot.agentId}
                      onChange={(e) => handleBotChange(index, 'agentId', e.target.value)}
                      placeholder="Agent ID"
                    />
                  </div>
                  <div>
                    <label htmlFor={`aiProvider-${index}`} className="block text-sm font-medium">
                      AI Provider
                    </label>
                    <select
                      id={`aiProvider-${index}`}
                      value={bot.aiProvider}
                      onChange={(e) => handleBotChange(index, 'aiProvider', e.target.value)}
                      className="w-full p-2 rounded border border-gray-400 bg-gray-700 text-white"
                    >
                      <option value="" disabled>
                        Seleccionar Proveedor de AI
                      </option>
                      <option value="VAPI">VAPI</option>
                      <option value="RETELL">RETELL</option>
                    </select>
                  </div>
                  <Button
                    type="button"
                    onClick={() => handleRemoveBot(index)}
                    className="mt-2"
                    color="error"
                  >
                    Eliminar Bot
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={handleAddBot} className="mt-4">
                Agregar otro Bot
              </Button>
              <div>
                <label htmlFor="costPerMinIA" className="block text-sm font-medium">
                  Costo por Minuto de IA
                </label>
                <Input
                  id="costPerMinIA"
                  fullWidth
                  value={costPerMinIA}
                  onChange={(e) => setCostPerMinIA(e.target.value)}
                  placeholder="Costo por Minuto de IA"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="costPerMinTransfer" className="block text-sm font-medium">
                  Costo por Minuto de Transferencia
                </label>
                <Input
                  id="costPerMinTransfer"
                  fullWidth
                  value={costPerMinTransfer}
                  onChange={(e) => setCostPerMinTransfer(e.target.value)}
                  placeholder="Costo por Minuto de Transferencia"
                  type="number"
                />
              </div>
              <Button type="submit" fullWidth>
                Agregar Cliente
              </Button>
            </form>
          </CardBody>
          <CardFooter className="text-xs text-gray-400">Todos los campos son obligatorios</CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default AddCustomerForm;