import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Card, CardBody, CardHeader, CardFooter } from "@nextui-org/react";
import { toast } from "react-hot-toast";
import Sidebar from "./Sidebar";

const CustomerId = () => {
  const { userId } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      toast.error("No estás autenticado. Por favor, inicia sesión.");
      return;
    }

    const fetchCustomerData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/customers/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomerData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        toast.error("Error al obtener datos del cliente.");
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [userId]);

  if (loading) {
    return <div className="text-center text-white">Cargando...</div>;
  }

  if (!customerData) {
    return (
      <div className="text-center text-white">No se encontraron datos.</div>
    );
  }

  return (
    <div className="flex bg-gray-900 min-h-screen">
      <Sidebar />
      <div className="p-6 flex-grow">
        <Card className="bg-gray-800 text-white w-full shadow-lg rounded-lg">
          <CardHeader className="text-2xl font-bold border-b border-gray-700 mb-4">
            Información del Cliente: {customerData.companyName}
          </CardHeader>
          <CardBody className="space-y-6">
            <div>
              <h4 className="text-lg font-semibold">Nombre de la Empresa:</h4>
              <p className="text-base">{customerData.companyName}</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold">Responsable:</h4>
              <p className="text-base">{customerData.responsibleName}</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold">Contacto:</h4>
              <p className="text-base">{customerData.contact}</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                Descripción de la Solución:
              </h4>
              <p className="text-base">{customerData.solutionDescription}</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                Costo por minuto de IA:
              </h4>
              <p className="text-base">
                ${customerData.totalCostIA}
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold">
                Costo por minuto de transferencia:
              </h4>
              <p className="text-base">${customerData.totalCostTransfer}</p>
            </div>

            <div>
              <h4 className="text-lg font-semibold">Bots:</h4>
              <ul className="list-disc ml-5 text-base space-y-2">
                {customerData.bots.map((bot, index) => (
                  <li key={index} className="bg-gray-700 p-2 rounded-md">
                    <p>
                      <strong>API Token:</strong> {bot.apiToken}
                    </p>
                    <p>
                      <strong>Agent ID:</strong> {bot.agentId}
                    </p>
                    <p>
                      <strong>AI Provider:</strong> {bot.aiProvider}
                    </p>
                    <p>
                      <strong>Total Mins IA:</strong> {bot.totalMinsIA}
                    </p>
                    <p>
                      <strong>Total Mins Transfer:</strong>{" "}
                      {bot.totalMinsTransfer}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold">Historial de Consumo:</h4>
              <ul className="list-disc ml-5 text-base space-y-2">
                {customerData.consumptionHistory.map((record, index) => (
                  <li key={index} className="bg-gray-700 p-2 rounded-md">
                    <p>
                      <strong>Estado:</strong> {record.status}
                    </p>
                    <p>
                      <strong>Total Minutos IA:</strong> {record.totalMinsIA}
                    </p>
                    <p>
                      <strong>Total Minutos Transferencia:</strong>{" "}
                      {record.totalMinsTransfer}
                    </p>
                    <p>
                      <strong>Total:</strong> {record.total}
                    </p>
                    <p>
                      <strong>Fecha de Pago:</strong>{" "}
                      {record.date_of_payment
                        ? new Date(record.date_of_payment).toLocaleDateString()
                        : "N/A"}
                    </p>
                    <div>
                      <h4 className="text-lg font-semibold">Costo Total IA:</h4>
                      <p className="text-base">
                        ${(
                          record.totalMinsIA * customerData.totalCostIA
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold">
                        Costo Total Transferencia:
                      </h4>
                      <p className="text-base">
                        ${(
                          record.totalMinsTransfer *
                          customerData.totalCostTransfer
                        ).toFixed(2)}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </CardBody>
          <CardFooter className="text-xs text-gray-500 border-t border-gray-700 mt-4 pt-2">
            Creado el: {new Date(customerData.createdAt).toLocaleDateString()}
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default CustomerId;