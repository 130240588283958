import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import Signup from '../components/Signup'
import Signin from '../components/Signin'
import Dashboard from '../pages/Dashboard/Dashboard'
import CustomerId from '../pages/Dashboard/Components/CustomerId'
import UpdateCustomerForm from '../pages/Dashboard/Components/UpdateCustomerForm'

function MyRoutes() {
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 3000,
        }}
      />
      <Routes>
        <Route path="/" exact element={<Signup />} />
        <Route path="/login" exact element={<Signin />} />
        <Route path="/dashboard" exact element={<Dashboard />} />
        <Route path="/customers/:userId" element={<CustomerId />} />
        <Route path="/customers/edit/:customerId" element={<UpdateCustomerForm />} />
      </Routes>
    </>
  );
}

export default MyRoutes