import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';
import Sidebar from './Components/Sidebar';
import Home from './Components/Home';
import AddCustomerForm from './Components/AddCustomerForm';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [activeView, setActiveView] = useState('home');  // State to track the active view
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el token está presente
    const token = Cookies.get('token');
    if (!token) {
      toast.error('No estás autenticado. Por favor, inicia sesión.');
      navigate('/login');
      return;
    }

    // Cargar datos del usuario
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data);
      } catch (error) {
        toast.error('Error al cargar los datos del usuario.');
        console.error(error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    // Limpiar la cookie y redireccionar al usuario
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    toast.success('Has cerrado sesión exitosamente.');
    navigate('/login');
  };

  if (!userData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="bg-[#0A0A0B] flex">
      <Sidebar userData={userData} handleLogout={handleLogout} setActiveView={setActiveView} />
      <div className="flex-grow p-4">
        {activeView === 'home' && <Home userData={userData} />}
        {activeView === 'addCustomer' && <AddCustomerForm userData={userData} />}
      </div>
    </div>
  );
};

export default Dashboard;