import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardFooter,
} from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

const Signin = () => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/login`, {
        email,
        password,
      });

      // Devuelve un JSON con mensaje o datos del usuario registrado
      const token = response.data.accessToken;
      if (token) {
        // Guardar el token en una cookie
        Cookies.set("token", token, { expires: 7, secure: true, sameSite: 'strict' });
        toast.success(`${response.data.message}!`);
        navigate("/dashboard");
      } else {
        throw new Error("No se recibió un token de autenticación");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Error al iniciar sesión. Inténtalo de nuevo.");
      setError(err.response?.data?.message || "Error al iniciar sesión. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0A0A0B]">
      <Card className="w-full max-w-sm p-6 bg-[#202023]">
        <CardHeader className="space-y-2 flex-col items-start">
          <h4 className="text-lg font-bold text-white">Iniciar sesión</h4>
          <p className="text-sm text-white">
            Ingresa tu correo electrónico para acceder a tu cuenta.
          </p>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardBody className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white"
              >
                Correo electrónico
              </label>
              <Input
                id="email"
                type="email"
                placeholder="ejemplo@dominio.com"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-white"
              >
                Contraseña
              </label>
              <Input
                id="password"
                type="password"
                placeholder="Tu contraseña"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <Button fullWidth type="submit" disabled={loading}>
              {loading ? "Iniciando sesión..." : "Iniciar sesión"}
            </Button>
          </CardBody>
        </form>
        <CardFooter className="flex justify-center">
          <p className="text-sm text-white">
            ¿No tienes una cuenta?{" "}
            <Link to="/" className="text-blue-500 hover:underline">
              Regístrate
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Signin;