import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@nextui-org/react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Home = ({ userData }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      toast.error("No estás autenticado. Por favor, inicia sesión.");
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/customers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleDelete = async (customerId) => {
    const token = Cookies.get("token");
    if (!token) {
      toast.error("No estás autenticado. Por favor, inicia sesión.");
      navigate("/login");
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Cliente eliminado exitosamente");
      setData(data.filter((item) => item._id !== customerId));
    } catch (error) {
      console.error("Error deleting customer:", error);
      toast.error("Error al eliminar cliente");
    }
  };

  return (
    <div className="p-4 space-y-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {data.map((item) => (
        <Card key={item._id} className="bg-gray-800 text-white">
          <CardHeader className="text-lg font-bold">
            <span
              onClick={() =>
                navigate(`/customers/${item._id}`, { state: { userData } })
              }
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {item.companyName}
            </span>
          </CardHeader>
          <CardBody className="space-y-2">
            <h4 className="text-sm font-semibold">
              Responsable: {item.responsibleName}
            </h4>
            <h4 className="text-sm">Contacto: {item.contact}</h4>
            <h4 className="text-xs">
              Creado el: {new Date(item.createdAt).toLocaleDateString()}
            </h4>
          </CardBody>
          <CardFooter className="flex justify-between">
            <Button
              color="warning"
              variant="shadow"
              className="text-sx w-1/4 m-1"
              style={{ color: "#FFFFFF" }}
              onClick={() => navigate(`/customers/edit/${item._id}`)}
            >
              Editar info
            </Button>
            <Button
              color="danger"
              variant="shadow"
              className="text-xs w-1/4 m-1"
              onClick={() => handleDelete(item._id)}
            >
              Eliminar cliente
            </Button>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default Home;
